<template>
<el-container>
  <nav-main></nav-main>
  <el-main>
    <div class="mainbox">
      <router-view></router-view>
    </div>
    <footer-all></footer-all>
   </el-main>
</el-container>
</template>
<script>
import NavMain from "@/components/nav/navMain";
import FooterAll from "@/components/Footer/footerall";
export default {
    components :{
        NavMain,
        FooterAll
    },
  data() {
    return {

    };
  },
  methods: {

  }
};
</script>
<style lang="less" scoped>
.el-container {
    flex-direction: column;
  height: 100%;
  .el-main {
padding: 0;
  }
}

</style>